<content-loading-animation *ngIf="loading || init"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && !customError && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading && !init">
    <div zmid="title" class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isEdit">{{ "CREATE_NEW_BROADCASTER_CLUSTER" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_BROADCASTER_CLUSTER" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="form-nav-bar">
        <ul class="nav justify-content-center">
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('info')"
                    [ngClass]="{
                        active: currentSection === 'info',
                        error: customError || (form.submitted && infoHasErrors(form))
                    }"
                    >{{ "INFO" | translate }}</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('settings')"
                    [ngClass]="{
                        active: currentSection === 'settings',
                        error: customError || (form.submitted && settingsHasErrors(form))
                    }"
                    >{{ "CONFIGURATION" | translate }}</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="showAdvanced = true; scrollTo('advanced')"
                    [ngClass]="{ active: currentSection === 'advanced', error: customError || (form.submitted && advancedHasErrors(form)) }"
                    >{{ "ADVANCED" | translate }}</a
                >
            </li>
        </ul>
    </div>
    <div class="content-area pt-0" scrollSpy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS" id="scrollParent">
                    <section id="info">
                        <div class="form-section-title">
                            <h3
                                class="section-title mt-3"
                                title="{{ 'INFO' | translate }}"
                                [ngClass]="{ 'status-error': customError || (form.submitted && infoHasErrors(form)) }"
                            >
                                {{ "INFO" | translate }}
                            </h3>
                        </div>
                        <!-- Name -->
                        <div class="form-group" *ngIf="!isAzureScaling() && !isGCPScaling()">
                            <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ></label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="{{ 'NAME' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                [(ngModel)]="cluster.name"
                                (keydown)="clusterNameChange($event)"
                                duplicateName="{{ clusterNames }}"
                                required
                                minlength="{{ minLength }}"
                                pattern="{{ constants.validators.name_without_trailing_space }}"
                                #name="ngModel"
                            />
                            <div *ngIf="name.invalid" class="invalid-feedback">
                                <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="name.errors.minlength">
                                    {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                                </div>
                                <div *ngIf="name.errors.pattern">
                                    {{ "NAME" | translate }} {{ "CAN_NOT_END_OR_START_BLANC_SPACE" | translate }} nor
                                    {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} . ' " `
                                </div>
                                <div *ngIf="name.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Azure Name -->
                        <div class="form-group" *ngIf="isAzureScaling()">
                            <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ></label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="{{ 'NAME' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                [(ngModel)]="cluster.name"
                                (keydown)="clusterNameChange($event)"
                                duplicateName="{{ clusterNames }}"
                                required
                                minlength="{{ minLength }}"
                                maxlength="50"
                                pattern="^[-\w.]*[-\w]{1}$"
                                #name="ngModel"
                            />
                            <div *ngIf="name.invalid" class="invalid-feedback">
                                <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="name.errors.minlength">
                                    {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                                </div>
                                <div *ngIf="name.errors.maxlength">
                                    {{ "NAME" | translate }} {{ "CAN_NOT_BE_MORE_THAN" | translate }} 50 {{ "CHARACTERS_LONG" | translate }}.
                                </div>
                                <div *ngIf="name.errors.pattern">{{ "AZURE_CLUSTER_NAME_ONLY_ALLOWS" | translate }}.</div>
                                <div *ngIf="name.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            </div>
                        </div>
                        <!-- GCP Name -->
                        <div class="form-group" *ngIf="isGCPScaling()">
                            <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ></label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="{{ 'NAME' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                [(ngModel)]="cluster.name"
                                (keydown)="clusterNameChange($event)"
                                duplicateName="{{ clusterNames }}"
                                required
                                minlength="{{ minLength }}"
                                maxlength="63"
                                pattern="^([a-zA-Z]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?)$"
                                #name="ngModel"
                            />
                            <div *ngIf="name.invalid" class="invalid-feedback">
                                <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="name.errors.minlength">
                                    {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                                </div>
                                <div *ngIf="name.errors.maxlength">
                                    {{ "NAME" | translate }} {{ "CAN_NOT_BE_MORE_THAN" | translate }} 63 {{ "CHARACTERS_LONG" | translate }}.
                                </div>
                                <div *ngIf="name.errors.pattern">{{ "GCP_CLUSTER_NAME_MUST" | translate }}.</div>
                                <div *ngIf="name.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="resource"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>

                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="cluster.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                        <!-- DNS Prefix -->
                        <div class="form-group" *ngIf="!isEdit">
                            <label for="dns_prefix" [ngClass]="{ 'is-invalid': form.submitted && dns_prefix.errors }"
                                >{{ "DNS_PREFIX" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><fa-icon icon="lock" size="sm" [ngbTooltip]="DNSContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #DNSContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.CLUSTER_DNS_PREFIX" | translate }}
                                    </p></ng-template
                                ></label
                            >
                            <div class="input-group">
                                <input
                                    type="text"
                                    id="dns_prefix"
                                    name="dns_prefix"
                                    placeholder="{{ 'DNS_PREFIX' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && dns_prefix.errors }"
                                    [(ngModel)]="cluster.dns_prefix"
                                    (keydown)="clusterPrefixTouch()"
                                    required
                                    minlength="{{ minLength }}"
                                    duplicateName="{{ clusterDNSPrefixes }}"
                                    pattern="^[a-z0-9]+([a-z0-9\-]*[a-z0-9]+)?$"
                                    #dns_prefix="ngModel"
                                />
                                <span class="input-group-text">.{{ applicationHost }}</span>
                                <div *ngIf="dns_prefix.invalid" class="invalid-feedback">
                                    <div *ngIf="dns_prefix.errors.required">{{ "DNS_PREFIX" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    <div *ngIf="dns_prefix.errors.duplicateName">{{ "DNS_PREFIX" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                                    <div *ngIf="dns_prefix.errors.pattern">{{ "DNS_PREFIX" | translate }} {{ "INVALID" | translate }}.</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="isEdit">
                            <label for="dns_prefix">{{ "DNS_PREFIX" | translate }}</label>
                            <input
                                type="text"
                                readonly
                                class="form-control-plaintext"
                                id="dns_prefix"
                                name="dns_prefix"
                                value="{{ dnsPrefix }}.{{ applicationHost }}"
                            />
                        </div>
                        <!-- DTLS -->
                        <fieldset class="form-group">
                            <legend for="dtls">{{ "DTLS" | translate }}</legend>
                            <mat-button-toggle-group name="dtls" aria-label="dtls" [(ngModel)]="cluster.dtls">
                                <mat-button-toggle [value]="0">{{ "CLEAR_ONLY" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="1">{{ "DUAL_MODE" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="2">{{ "DTLS_ONLY" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                            <br />
                            <small>{{ "DTLS_REQUIRES_V12.2" | translate }}</small>
                        </fieldset>
                        <!-- Ignore DTLS -->
                        <div class="form-group" *ngIf="cluster.dtls">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="allow_ignore_dtls_cert"
                                    name="allow_ignore_dtls_cert"
                                    [(ngModel)]="cluster.allow_ignore_dtls_cert"
                                />
                                <label class="form-check-label" for="allow_ignore_dtls_cert"
                                    >{{ "IGNORE_DTLS_CERTIFICATE_WHEN_NOT_SUPPORTED" | translate }}
                                </label>
                            </div>
                            <small>{{ "OLDER_VERSIONS_OF_ZIXI_SOFTWARE_DONT_SUPPORT_VERIFICATION" | translate }}</small>
                        </div>
                        <!-- SCTE-35 Log -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="log_scte" name="log_scte" [(ngModel)]="cluster.configure_scte_reports" />
                                <label class="form-check-label" for="log_scte">{{ "LOG_SCTE_35" | translate }}</label>
                            </div>
                            <small>{{ "SCTE_VERSION_REQUIRED" | translate }}</small>
                        </div>
                        <!-- RTMP Server ON/OFF-->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="rtmp_server" name="rtmp_server" [(ngModel)]="rtmpOn" />
                                <label class="form-check-label" for="rtmp_server">{{ "RTMP_SERVER" | translate }}</label>
                            </div>
                        </div>
                        <!-- RTMP Server Port -->
                        <div class="form-group" *ngIf="rtmpOn">
                            <label for="rtmp_server_port">{{ "RTMP_SERVER_PORT" | translate }}</label>
                            <input
                                type="number"
                                class="form-control"
                                id="rtmp_server_port"
                                name="rtmp_server_port"
                                [(ngModel)]="cluster.rtmp_input_port"
                                placeholder="{{ 'RTMP_SERVER_DEFAULT_PORT' | translate }}"
                                min="1"
                                max="65535"
                                pattern="^\d+$"
                                #rtmp_server_port="ngModel"
                                [ngClass]="{ 'is-invalid': form.submitted && rtmp_server_port.errors }"
                            />
                            <div *ngIf="rtmp_server_port.invalid" class="invalid-feedback">
                                <div *ngIf="rtmp_server_port.errors.required">{{ "RTMP_SERVER_PORT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="rtmp_server_port.errors.pattern">
                                    {{ "RTMP_SERVER_PORT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                </div>
                                <div *ngIf="rtmp_server_port.errors.min || rtmp_server_port.errors.max">
                                    {{ "RTMP_SERVER_PORT" | translate }} {{ "MUST_BE_BETWEEN_1_AND_65535" | translate }}.
                                </div>
                            </div>
                        </div>
                        <!-- Alternative Cluster -->
                        <div class="form-group">
                            <label for="alt_cluster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alt_cluster_id?.errors }"
                                >{{ "ALTERNATIVE_CLUSTER" | translate }} ({{ "DISASTER_RECOVERY" | translate }})
                            </label>
                            <zx-cluster-select
                                name="alt_broadcaster_cluster_id"
                                [(model)]="cluster.alt_cluster_id"
                                [ngModel]="cluster.alt_cluster_id"
                                [ngClass]="{
                                    'is-invalid': form.submitted && (cluster.id === cluster.alt_cluster_id || form.controls.alt_cluster_id?.errors)
                                }"
                                [exclude]="[cluster.id] ?? undefined"
                                [clearable]="true"
                            ></zx-cluster-select>
                        </div>
                    </section>
                    <section id="settings">
                        <div class="form-section-title">
                            <h3
                                class="section-title"
                                title="{{ 'SETTINGS' | translate }}"
                                [ngClass]="{ 'status-error': customError || (form.submitted && settingsHasErrors(form)) }"
                            >
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Scaling Account -->
                        <div class="form-group" *ngIf="!isEdit">
                            <label for="scalingAccountSelect" [ngClass]="{ 'is-invalid': form.submitted && scalingAccountSelect.errors }">
                                {{ "SCALING_ACCOUNT" | translate
                                }}<fa-icon icon="lock" size="sm" [ngbTooltip]="SAContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #SAContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.CLUSTER_SCALING_ACCOUNT" | translate }}
                                    </p></ng-template
                                >
                            </label>
                            <ng-select
                                id="scalingAccountSelect"
                                name="scalingAccountSelect"
                                required
                                [items]="scalingAccounts"
                                [clearable]="false"
                                bindValue="id"
                                bindLabel="name"
                                groupBy="groupName"
                                [loading]="loadingScalingAccounts"
                                placeholder="{{ 'SELECT_SCALING_ACCOUNT' | translate }}"
                                [(ngModel)]="scalingAccountSelector"
                                #scalingAccountSelect="ngModel"
                                (ngModelChange)="scalingAccountChanged()"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && scalingAccountSelect.errors }"
                            >
                            </ng-select>
                            <div *ngIf="scalingAccountSelect.invalid" class="invalid-feedback">
                                <div *ngIf="scalingAccountSelect.errors.required">{{ "SCALING_ACCOUNT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="isEdit">
                            <label for="scalingAccount">{{ "SCALING_ACCOUNT" | translate }}</label>
                            <input
                                type="text"
                                readonly
                                class="form-control-plaintext"
                                id="scalingAccount"
                                name="scalingAccount"
                                value="{{ scalingAccount.type | uppercase | translate }} - {{ scalingAccount.name }}"
                            />
                        </div>
                        <!---------------------------------------------------------->
                        <!-- AWS -->
                        <div *ngIf="isAWSScaling()">
                            <!--<h3 title="{{ 'AWS_SETTINGS' | translate }}">{{ "AWS_SETTINGS" | translate }}</h3>-->
                            <!-- Region: AWS -->
                            <div class="form-group" *ngIf="!this.isEdit">
                                <label for="region" [ngClass]="{ 'is-invalid': form.submitted && region.errors }">
                                    {{ "REGION" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><fa-icon icon="lock" size="sm" [ngbTooltip]="RegionContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #RegionContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.CLUSTER_REGION" | translate }}
                                        </p></ng-template
                                    >
                                </label>
                                <ng-select
                                    id="region"
                                    name="region"
                                    required
                                    [items]="autoscalingRegions"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'REGION' | translate }}"
                                    [(ngModel)]="scalingRegion"
                                    (change)="scalingRegionChanged()"
                                    bindLabel="name"
                                    bindValue="id"
                                    #region="ngModel"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && region.errors }"
                                    [loading]="loadingScalingRegions"
                                >
                                </ng-select>
                                <div *ngIf="region.invalid" class="invalid-feedback">
                                    <div *ngIf="region.errors.required">{{ "REGION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="isEdit">
                                <label for="region">{{ "REGION" | translate }}</label>
                                <input
                                    type="text"
                                    readonly
                                    class="form-control-plaintext"
                                    id="region"
                                    name="region"
                                    value="{{ cluster._frontData.prettyRegionName || cluster.region }}"
                                />
                            </div>
                            <!-- Key Pair: AWS -->
                            <div class="form-group" *ngIf="!this.isEdit">
                                <label for="key_pair" [ngClass]="{ 'is-invalid': form.submitted && key_pair.errors }">
                                    {{ "KEY_PAIR" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><fa-icon icon="lock" size="sm" [ngbTooltip]="KeyPairContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #KeyPairContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.CLUSTER_KEY_PAIR" | translate }}
                                        </p></ng-template
                                    >
                                </label>
                                <ng-select
                                    id="key_pair"
                                    name="key_pair"
                                    required
                                    [items]="keyList"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'KEY_PAIR' | translate }}"
                                    [(ngModel)]="key"
                                    bindLabel="name"
                                    bindValue="id"
                                    #key_pair="ngModel"
                                    [disabled]="!scalingRegion"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && key_pair.errors }"
                                    [loading]="loadingRegionData"
                                >
                                </ng-select>
                                <small *ngIf="scalingRegion && keyList && keyList.length === 0">
                                    No key pairs configured in this region, click
                                    <a href="https://console.aws.amazon.com/ec2/v2/home?region={{ scalingRegion }}#KeyPairs" target="_blank">here</a> to create
                                    one via the AWS console
                                </small>
                                <div *ngIf="key_pair.invalid" class="invalid-feedback">
                                    <div *ngIf="key_pair.errors.required">{{ "KEY_PAIR" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="isEdit">
                                <label for="key_pair">{{ "KEY_PAIR" | translate }}</label>
                                <input type="text" readonly class="form-control-plaintext" id="key_pair" name="key_pair" value="{{ cluster.key_pair }}" />
                            </div>
                            <!-- VPC: AWS -->
                            <div class="form-group" *ngIf="!this.isEdit">
                                <label for="vpc" [ngClass]="{ 'is-invalid': form.submitted && vpc.errors }">
                                    {{ "VPC" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><fa-icon icon="lock" size="sm" [ngbTooltip]="VPCContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #VPCContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.CLUSTER_VPC" | translate }}
                                        </p></ng-template
                                    >
                                </label>
                                <ng-select
                                    id="vpc"
                                    name="vpc"
                                    required
                                    [items]="vpcOptions"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'VPC' | translate }}"
                                    [(ngModel)]="cluster.vpc"
                                    bindLabel="name"
                                    bindValue="id"
                                    #vpc="ngModel"
                                    [disabled]="!scalingRegion"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && vpc.errors }"
                                    [loading]="loadingRegionData"
                                    (change)="vpcChanged()"
                                >
                                </ng-select>
                                <div *ngIf="vpc.invalid" class="invalid-feedback">
                                    <div *ngIf="vpc.errors.required">{{ "VPC" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="isEdit">
                                <label for="vpc">{{ "VPC" | translate }}</label>
                                <input type="text" readonly class="form-control-plaintext" id="vpc" name="vpc" value="{{ cluster.vpc }}" />
                            </div>
                            <!-- Subnets: AWS -->
                            <div class="form-group">
                                <label for="subnets" [ngClass]="{ 'is-invalid': form.submitted && subnets.errors }">
                                    {{ "SUBNETS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                                </label>
                                <ng-select
                                    id="subnets"
                                    name="subnets"
                                    required
                                    [items]="subnetOptions"
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                    [clearSearchOnAdd]="true"
                                    [hideSelected]="true"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'SUBNETS' | translate }}"
                                    [(ngModel)]="clusterSubnet"
                                    bindLabel="name"
                                    bindValue="id"
                                    #subnets="ngModel"
                                    [disabled]="!scalingRegion || !cluster.vpc"
                                    [ngClass]="{ 'form-control is-invalid': (form.submitted || hasMixedSubnets) && subnets.errors }"
                                    (change)="subnetChanged(subnets)"
                                >
                                </ng-select>
                                <div *ngIf="subnets.invalid" class="status-error">
                                    <div *ngIf="subnets.errors.hasMixedSubnets">{{ "NOT_INTERCHANGEABLE_WAVELENGTH_SUBNETS" | translate }}.</div>
                                </div>
                                <div *ngIf="subnets.invalid" class="invalid-feedback">
                                    <div *ngIf="subnets.errors.required">{{ "AT_LEAST_1_SUBNET_IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Security Groups: AWS -->
                            <div class="form-group">
                                <label for="security_groups" [ngClass]="{ 'is-invalid': form.submitted && security_groups.errors }">
                                    {{ "SECURITY_GROUPS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                                </label>
                                <ng-select
                                    id="security_groups"
                                    name="security_groups"
                                    [items]="securityGroupOptions"
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                    [clearSearchOnAdd]="true"
                                    [hideSelected]="true"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'SECURITY_GROUPS' | translate }}"
                                    [(ngModel)]="clusterSecurityGroup"
                                    bindLabel="name"
                                    bindValue="id"
                                    #security_groups="ngModel"
                                    [disabled]="!scalingRegion || !cluster.vpc"
                                    required="true"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && security_groups.errors }"
                                >
                                </ng-select>
                                <div *ngIf="security_groups.invalid" class="invalid-feedback">
                                    <div *ngIf="security_groups.errors.required">{{ "AT_LEAST_1_SECURITY_GROUP_IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>

                            <!-- Custom image: AWS -->
                            <app-select-generic-field
                                elementId="custom-image"
                                title="{{ 'AWS_AMI' | translate }}"
                                valueToBind="id"
                                [formControl]="customImageControl"
                                placeHolder="{{ 'SELECT' | translate }} {{ 'AWS_AMI' | translate }}"
                                [isParentFormSubmitted]="submitted"
                                [isRestartRequiredTip]="isEdit"
                                [items]="customImages$ | async"
                            >
                            </app-select-generic-field>

                            <!-- Architecture: AWS -->
                            <fieldset class="form-group">
                                <legend for="load_balance">{{ "ARCHITECTURE" | translate }}</legend>
                                <mat-button-toggle-group
                                    name="architecture"
                                    aria-label="architecture"
                                    [(ngModel)]="architecture"
                                    (change)="updateVersionAndInstanceTypesOptions()"
                                    [disabled]="customImageControl.value !== 0"
                                >
                                    <mat-button-toggle [disabled]="isSubnetTypeWavelength" value="arm">64-bit (Arm)</mat-button-toggle>
                                    <mat-button-toggle value="x86">64-bit (x86)</mat-button-toggle>
                                </mat-button-toggle-group>
                                <br />
                                <small *ngIf="isSubnetTypeWavelength">Wavelength only supports x86 architecture</small>
                            </fieldset>
                            <!-- Instance Type: AWS -->
                            <div class="form-group">
                                <label for="instance_type" [ngClass]="{ 'is-invalid': form.submitted && instance_type.errors }">
                                    {{ "INSTANCE_TYPE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                                </label>
                                <ng-select
                                    class="multiline-options"
                                    id="instance_type"
                                    name="instance_type"
                                    required
                                    [items]="instanceTypesWithPricing"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'INSTANCE_TYPE' | translate }}"
                                    [(ngModel)]="cluster.instance_type"
                                    bindLabel="name"
                                    bindValue="id"
                                    #instance_type="ngModel"
                                    [disabled]="!scalingRegion"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && instance_type.errors }"
                                    [loading]="loadingRegionData"
                                    (change)="updateVersionAndInstanceTypesOptions()"
                                >
                                </ng-select>
                                <div class="form-check form-check-inline mt-1" *ngIf="architecture === 'x86' && isSubnetTypeWavelength === false">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="_showDeprecatedInstanceTypes"
                                        name="_showDeprecatedInstanceTypes"
                                        [(ngModel)]="showDeprecatedInstanceTypes"
                                        (change)="updateVersionAndInstanceTypesOptions()"
                                    />
                                    <label class="form-check-label" for="_showDeprecatedInstanceTypes">{{
                                        "SHOW_DEPRECATED_INSTANCE_TYPES" | translate
                                    }}</label>
                                </div>
                                <div *ngIf="instance_type.invalid" class="invalid-feedback">
                                    <div *ngIf="instance_type.errors.required">{{ "INSTANCE_TYPE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Size: AWS -->
                            <div class="row">
                                <!-- Root Device Size: AWS -->
                                <div class="col-12 col-xs-6 mb-3">
                                    <label for="root_device_size" [ngClass]="{ 'is-invalid': form.submitted && root_device_size.errors }"
                                        >{{ "ROOT_DEVICE_SIZE" | translate }} [GiB]<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ></label>
                                    <input
                                        type="number"
                                        id="root_device_size"
                                        name="root_device_size"
                                        placeholder="{{ 'ROOT_DEVICE_SIZE' | translate }} [GiB]"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': form.submitted && root_device_size.errors }"
                                        [(ngModel)]="cluster.root_device_size"
                                        [min]="8"
                                        [max]="1000"
                                        required
                                        #root_device_size="ngModel"
                                    />
                                    <div *ngIf="root_device_size.invalid" class="invalid-feedback">
                                        <div *ngIf="root_device_size.errors.required">
                                            {{ "ROOT_DEVICE_SIZE" | translate }} {{ "IS_REQUIRED" | translate }}.
                                        </div>
                                        <div *ngIf="root_device_size.errors.min || root_device_size.errors.max">
                                            {{ "ROOT_DEVICE_SIZE" | translate }} {{ "MUST_BE_BETWEEN_8_AND_1000" | translate }} GiB.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Azure -->
                        <div *ngIf="isAzureScaling()">
                            <!--<h3 title="{{ 'AZURE_SETTINGS' | translate }}">{{ "AZURE_SETTINGS" | translate }}</h3>-->
                            <!-- Region: Azure -->
                            <div class="form-group" *ngIf="!this.isEdit">
                                <label for="region" [ngClass]="{ 'is-invalid': form.submitted && region.errors }">
                                    {{ "REGION" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><fa-icon icon="lock" size="sm" [ngbTooltip]="RegionContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #RegionContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.CLUSTER_REGION" | translate }}
                                        </p></ng-template
                                    >
                                </label>
                                <ng-select
                                    id="region"
                                    name="region"
                                    required
                                    [items]="autoscalingRegions"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'REGION' | translate }}"
                                    [(ngModel)]="scalingRegion"
                                    (change)="scalingRegionChanged()"
                                    bindLabel="name"
                                    bindValue="id"
                                    #region="ngModel"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && region.errors }"
                                    [loading]="loadingScalingRegions"
                                >
                                </ng-select>
                                <div *ngIf="region.invalid" class="invalid-feedback">
                                    <div *ngIf="region.errors.required">{{ "REGION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                                <div *ngIf="!loadingScalingRegions && regionsLoadError" class="error-note">
                                    {{ regionsLoadError }}
                                </div>
                            </div>
                            <div class="form-group" *ngIf="isEdit">
                                <label for="region">{{ "REGION" | translate }}</label>
                                <input
                                    type="text"
                                    readonly
                                    class="form-control-plaintext"
                                    id="region"
                                    name="region"
                                    value="{{ cluster._frontData.prettyRegionName || cluster.region }}"
                                />
                            </div>
                            <!-- Virtual Network: Azure -->
                            <div class="form-group" *ngIf="!this.isEdit">
                                <label for="vpc" [ngClass]="{ 'is-invalid': form.submitted && vpc.errors }">
                                    {{ "VIRTUAL_NETWORK" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><fa-icon icon="lock" size="sm" [ngbTooltip]="VNContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #VNContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.CLUSTER_VIRTUAL_NETWORK" | translate }}
                                        </p></ng-template
                                    >
                                </label>
                                <ng-select
                                    id="vpc"
                                    name="vpc"
                                    required
                                    [items]="vpcOptions"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'VIRTUAL_NETWORK' | translate }}"
                                    [(ngModel)]="cluster.vpc"
                                    bindLabel="name"
                                    bindValue="id"
                                    #vpc="ngModel"
                                    [disabled]="!scalingRegion"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && vpc.errors }"
                                    [loading]="loadingRegionData"
                                    (change)="vpcChanged()"
                                >
                                </ng-select>
                                <div *ngIf="vpc.invalid" class="invalid-feedback">
                                    <div *ngIf="vpc.errors.required">{{ "VIRTUAL_NETWORK" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="isEdit">
                                <label for="vpc">{{ "VIRTUAL_NETWORK" | translate }}</label>
                                <input type="text" readonly class="form-control-plaintext" id="vpc" name="vpc" value="{{ cluster._frontData.vpcName }}" />
                            </div>
                            <!-- Subnet: Azure -->
                            <div class="form-group">
                                <label for="subnet" [ngClass]="{ 'is-invalid': form.submitted && subnet.errors }">
                                    {{ "SUBNET" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                                </label>
                                <ng-select
                                    id="subnet"
                                    name="subnet"
                                    required
                                    [items]="subnetOptions"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'SUBNET' | translate }}"
                                    [(ngModel)]="clusterSubnet"
                                    bindLabel="name"
                                    bindValue="id"
                                    #subnet="ngModel"
                                    [disabled]="!scalingRegion || !cluster.vpc"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && subnet.errors }"
                                >
                                </ng-select>
                                <div *ngIf="subnet.invalid" class="invalid-feedback">
                                    <div *ngIf="subnet.errors.required">{{ "SUBNET" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Network Security Group: Azure -->
                            <div class="form-group">
                                <label for="network_security_group" [ngClass]="{ 'is-invalid': form.submitted && network_security_group.errors }">
                                    {{ "NETWORK_SECURITY_GROUP" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                                </label>
                                <ng-select
                                    id="network_security_group"
                                    name="network_security_group"
                                    required
                                    [items]="securityGroupOptions"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'NETWORK_SECURITY_GROUP' | translate }}"
                                    [(ngModel)]="clusterSecurityGroup"
                                    bindLabel="name"
                                    bindValue="id"
                                    #network_security_group="ngModel"
                                    [disabled]="!scalingRegion || !cluster.vpc"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && network_security_group.errors }"
                                >
                                </ng-select>
                                <div *ngIf="network_security_group.invalid" class="invalid-feedback">
                                    <div *ngIf="network_security_group.errors.required">
                                        {{ "NETWORK_SECURITY_GROUP" | translate }} {{ "IS_REQUIRED" | translate }}.
                                    </div>
                                </div>
                            </div>
                            <!-- Instance Size: Azure -->
                            <div class="form-group">
                                <label for="instance_size" [ngClass]="{ 'is-invalid': form.submitted && instance_size.errors }">
                                    {{ "INSTANCE_SIZE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                                </label>
                                <ng-select
                                    id="instance_size"
                                    name="instance_size"
                                    required
                                    [items]="azureVMSizes"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'INSTANCE_SIZE' | translate }}"
                                    [(ngModel)]="cluster.instance_type"
                                    bindLabel="name"
                                    bindValue="id"
                                    #instance_size="ngModel"
                                    [disabled]="!scalingRegion"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && instance_size.errors }"
                                    [loading]="loadingRegionData"
                                    (change)="updateVersionAndInstanceTypesOptions()"
                                >
                                    <ng-template ng-label-tmp let-item="item" *ngIf="!loading"
                                        ><strong>{{ item.name }}</strong
                                        >&nbsp;|&nbsp;{{ item.numberOfCores }}&nbsp;vcpu,&nbsp;{{ item.memoryInMB / 1024 }}&nbsp;GB memory</ng-template
                                    >
                                    <ng-template ng-label-tmp let-item="item" *ngIf="loading">{{ "LOADING" | translate }}...</ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                        <strong>{{ item.name }}</strong
                                        >&nbsp;|&nbsp;{{ item.numberOfCores }}&nbsp;vcpu,&nbsp;{{ item.memoryInMB / 1024 }}&nbsp;GB memory
                                    </ng-template>
                                </ng-select>
                                <div *ngIf="instance_size.invalid" class="invalid-feedback">
                                    <div *ngIf="instance_size.errors.required">{{ "INSTANCE_SIZE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                        </div>
                        <!-- GCP -->
                        <div *ngIf="isGCPScaling()">
                            <!--h3 title="{{ 'GCP_SETTINGS' | translate }}">{{ "GCP_SETTINGS" | translate }}</h3>-->
                            <!-- Region: GCP -->
                            <div class="form-group" *ngIf="!this.isEdit">
                                <label for="region" [ngClass]="{ 'is-invalid': form.submitted && region.errors }">
                                    {{ "REGION" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><fa-icon icon="lock" size="sm" [ngbTooltip]="RegionContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #RegionContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.CLUSTER_REGION" | translate }}
                                        </p></ng-template
                                    >
                                </label>
                                <ng-select
                                    id="region"
                                    name="region"
                                    required
                                    [items]="autoscalingRegions"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'REGION' | translate }}"
                                    [(ngModel)]="scalingRegion"
                                    (change)="scalingRegionChanged()"
                                    bindLabel="name"
                                    bindValue="id"
                                    #region="ngModel"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && region.errors }"
                                    [loading]="loadingScalingRegions"
                                >
                                </ng-select>
                                <div *ngIf="region.invalid" class="invalid-feedback">
                                    <div *ngIf="region.errors.required">{{ "REGION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="isEdit">
                                <label for="region">{{ "REGION" | translate }}</label>
                                <input
                                    type="text"
                                    readonly
                                    class="form-control-plaintext"
                                    id="region"
                                    name="region"
                                    value="{{ cluster._frontData.prettyRegionName || cluster.region }}"
                                />
                            </div>
                            <!-- Zones: GCP -->
                            <div class="form-group">
                                <label for="gcp_zones" [ngClass]="{ 'is-invalid': form.submitted && gcp_zones.errors }">
                                    {{ "GCP_ZONES" | translate }}<fa-icon icon="asterisk" size="xs" *ngIf="!isEdit"></fa-icon
                                    ><fa-icon
                                        icon="lock"
                                        size="sm"
                                        [ngbTooltip]="ZonesContent"
                                        triggers="hover click"
                                        [closeDelay]="500"
                                        *ngIf="!isEdit"
                                    ></fa-icon
                                    ><ng-template #ZonesContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.CLUSTER_ZONES" | translate }}
                                        </p></ng-template
                                    >
                                </label>
                                <ng-select
                                    id="gcp_zones"
                                    name="gcp_zones"
                                    required
                                    [items]="zonesOptions"
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                    [clearSearchOnAdd]="true"
                                    [hideSelected]="true"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'GCP_ZONES' | translate }}"
                                    [(ngModel)]="gcpZones"
                                    bindLabel="name"
                                    bindValue="id"
                                    #gcp_zones="ngModel"
                                    [loading]="loadingRegionData"
                                    [disabled]="!scalingRegion || isEdit"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && gcp_zones.errors }"
                                >
                                </ng-select>
                                <div *ngIf="gcp_zones.invalid" class="invalid-feedback">
                                    <div *ngIf="gcp_zones.errors.required">{{ "AT_LEAST_1_ZONE_IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Network: GCP -->
                            <div class="form-group">
                                <label for="network" [ngClass]="{ 'is-invalid': form.submitted && network.errors }">
                                    {{ "NETWORK" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                                </label>
                                <ng-select
                                    id="network"
                                    name="network"
                                    required
                                    [items]="subnetOptions"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'NETWORK' | translate }}"
                                    [(ngModel)]="clusterSubnet"
                                    bindLabel="name"
                                    bindValue="id"
                                    #network="ngModel"
                                    [disabled]="!scalingRegion"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && network.errors }"
                                >
                                </ng-select>
                                <div *ngIf="network.invalid" class="invalid-feedback">
                                    <div *ngIf="network.errors.required">{{ "NETWORK" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Machine Type: GCP -->
                            <div class="form-group">
                                <label for="machine_type" [ngClass]="{ 'is-invalid': form.submitted && machine_type.errors }">
                                    {{ "MACHINE_TYPE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                                </label>
                                <ng-select
                                    id="machine_type"
                                    name="machine_type"
                                    required
                                    [items]="gcpVMSizes"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'MACHINE_TYPE' | translate }}"
                                    [(ngModel)]="cluster.instance_type"
                                    bindLabel="name"
                                    bindValue="id"
                                    #machine_type="ngModel"
                                    [disabled]="!scalingRegion"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && machine_type.errors }"
                                    [loading]="loadingRegionData"
                                >
                                    <ng-template ng-label-tmp let-item="item" *ngIf="!loading"
                                        ><strong>{{ item.name }}</strong
                                        >&nbsp;|&nbsp;{{ item.numberOfCores }}&nbsp;vcpu,&nbsp;{{ item.memoryInMB / 1024 }}&nbsp;GB memory</ng-template
                                    >
                                    <ng-template ng-label-tmp let-item="item" *ngIf="loading">{{ "LOADING" | translate }}...</ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                        <strong>{{ item.name }}</strong
                                        >&nbsp;|&nbsp;{{ item.numberOfCores }}&nbsp;vcpu,&nbsp;{{ item.memoryInMB / 1024 }}&nbsp;GB memory
                                    </ng-template>
                                </ng-select>
                                <div *ngIf="machine_type.invalid" class="invalid-feedback">
                                    <div *ngIf="machine_type.errors.required">{{ "MACHINE_TYPE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- GPU Type: GCP -->
                            <div class="form-group">
                                <label for="nvidia_type" [ngClass]="{ 'is-invalid': form.submitted && nvidia_type.errors }">
                                    {{ "GPU_TYPE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                                </label>
                                <ng-select
                                    id="nvidia_type"
                                    name="nvidia_type"
                                    required
                                    [items]="nvidiaOptions"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'GPU_TYPE' | translate }}"
                                    [(ngModel)]="cluster.nvidia_type"
                                    bindLabel="name"
                                    bindValue="id"
                                    #nvidia_type="ngModel"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && nvidia_type.errors }"
                                >
                                </ng-select>
                                <div>
                                    <small>
                                        <a href="https://cloud.google.com/compute/docs/gpus/gpu-regions-zones" target="_blank">{{
                                            "GCP_GPU_AVAILABILITY" | translate
                                        }}</a>
                                    </small>
                                </div>
                                <div *ngIf="nvidia_type.invalid" class="invalid-feedback">
                                    <div *ngIf="nvidia_type.errors.required">{{ "GPU_TYPE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Root Device Size: GCP -->
                            <div class="row">
                                <!-- Root Device Size: AWS -->
                                <div class="col-12 col-xs-6 mb-3">
                                    <label for="root_device_size" [ngClass]="{ 'is-invalid': form.submitted && root_device_size.errors }"
                                        >{{ "ROOT_DEVICE_SIZE" | translate }} [GiB]<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ></label>
                                    <input
                                        type="number"
                                        id="root_device_size"
                                        name="root_device_size"
                                        placeholder="{{ 'ROOT_DEVICE_SIZE' | translate }} [GiB]"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': form.submitted && root_device_size.errors }"
                                        [(ngModel)]="cluster.root_device_size"
                                        [min]="8"
                                        [max]="1000"
                                        required
                                        #root_device_size="ngModel"
                                    />
                                    <div *ngIf="root_device_size.invalid" class="invalid-feedback">
                                        <div *ngIf="root_device_size.errors.required">
                                            {{ "ROOT_DEVICE_SIZE" | translate }} {{ "IS_REQUIRED" | translate }}.
                                        </div>
                                        <div *ngIf="root_device_size.errors.min || root_device_size.errors.max">
                                            {{ "ROOT_DEVICE_SIZE" | translate }} {{ "MUST_BE_BETWEEN_8_AND_1000" | translate }} GiB.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-xs-6"></div>
                            </div>
                        </div>
                        <!-- Shared -->
                        <div *ngIf="isAWSScaling() || isAzureScaling() || isGCPScaling()">
                            <!-- Activation Key -->
                            <div class="form-group">
                                <label for="activation_key" [ngClass]="{ 'is-invalid': form.submitted && activation_key.errors }"
                                    >{{ "ACTIVATION_KEY" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ></label>
                                <input
                                    type="text"
                                    id="activation_key"
                                    name="activation_key"
                                    placeholder="{{ 'ACTIVATION_KEY' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && activation_key.errors }"
                                    [(ngModel)]="cluster.activation_key"
                                    required
                                    #activation_key="ngModel"
                                />
                                <div *ngIf="activation_key.invalid" class="invalid-feedback">
                                    <div *ngIf="activation_key.errors.required">{{ "ACTIVATION_KEY" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- API User & Password -->
                            <fieldset *ngIf="cluster.broadcasters?.length === 0 || !isEdit">
                                <div class="row">
                                    <div class="col-12 col-xs-6 mb-3">
                                        <label for="api_user" [ngClass]="{ 'is-invalid': form.submitted && api_user.errors }"
                                            >{{ "API_USER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                        ></label>
                                        <div class="input-group">
                                            <input
                                                type="text"
                                                id="api_user"
                                                name="api_user"
                                                placeholder="{{ 'API_USER' | translate }}"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid': form.submitted && api_user.errors }"
                                                [(ngModel)]="cluster.api_user"
                                                required
                                                #api_user="ngModel"
                                            />
                                            <app-copy-button title="{{ 'COPY_PASSWORD' | translate }}" [stringToCopy]="cluster.api_user"></app-copy-button>
                                        </div>
                                        <div *ngIf="api_user.invalid" class="invalid-feedback">
                                            <div *ngIf="api_user.errors.required">{{ "API_USER" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-xs-6 mb-3">
                                        <app-password-input
                                            [(passwordInput)]="cluster.api_password"
                                            [isFormSubmitted]="form.submitted"
                                            [isEdit]="isEdit"
                                        ></app-password-input>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset *ngIf="cluster.broadcasters?.length > 0 && isEdit">
                                <div class="row">
                                    <div class="col-12 col-xs-6 mb-3">
                                        <label for="api_user"
                                            >{{ "API_USER" | translate
                                            }}<fa-icon icon="lock" size="sm" [ngbTooltip]="UserContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                            ><ng-template #UserContent
                                                ><p class="mb-0">
                                                    {{ "TOOLTIP.CLUSTER_API_USER" | translate }}
                                                </p></ng-template
                                            ></label
                                        >
                                        <input
                                            type="text"
                                            readonly
                                            class="form-control-plaintext"
                                            id="api_user"
                                            name="api_user"
                                            value="{{ cluster.api_user }}"
                                        />
                                    </div>
                                    <div class="col-12 col-xs-6 mb-3">
                                        <label for="api_password"
                                            >{{ "API_PASSWORD" | translate
                                            }}<fa-icon icon="lock" size="sm" [ngbTooltip]="PWContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                            ><ng-template #PWContent
                                                ><p class="mb-0">
                                                    {{ "TOOLTIP.CLUSTER_API_PASSWORD" | translate }}
                                                </p></ng-template
                                            ></label
                                        >
                                        <div>
                                            <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">
                                                {{ "SHOW" | translate }}
                                            </a>
                                            <span *ngIf="pwVisible">
                                                {{ cluster.api_password }}
                                                <app-password-strength-icon [password]="cluster.api_password"></app-password-strength-icon>
                                            </span>
                                            /
                                            <a href="javascript:void(0)" (click)="copyPassword(cluster.api_password)">
                                                {{ "COPY" | translate }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <!-- Broadcaster Version -->
                            <div class="form-group">
                                <label for="broadcaster_version">
                                    {{ "BROADCASTER_VERSION" | translate }}
                                </label>
                                <ng-select
                                    id="broadcaster_version"
                                    name="broadcaster_version"
                                    [items]="broadcasterVersions"
                                    [clearable]="false"
                                    bindValue="file_id"
                                    bindLabel="version"
                                    placeholder="{{ 'SELECT_BROADCASTER_VERSION' | translate }}"
                                    [(ngModel)]="cluster.zcp_version_id"
                                    #broadcaster_version="ngModel"
                                    required
                                >
                                    <ng-template ng-label-tmp let-item="item"
                                        ><fa-icon icon="lock" class="me-1" size="sm" *ngIf="item.is_private" />{{ item.version | translate }}
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                        <fa-icon icon="lock" class="me-1" size="sm" *ngIf="item.is_private" />{{ item.version | translate }}
                                    </ng-template>
                                </ng-select>
                                <div *ngIf="broadcaster_version.invalid" class="invalid-feedback">
                                    <div *ngIf="broadcaster_version.errors.required">
                                        {{ "BROADCASTER_VERSION" | translate }} {{ "IS_REQUIRED" | translate }}.
                                    </div>
                                </div>
                                <div>
                                    <small *ngIf="isEdit">
                                        {{ "CHANGES_ON_THIS_SCREEN_WILL_ONLY_AFFECT_NEWLY_ADDED_BROADCASTERS" | translate }}
                                    </small>
                                </div>
                                <div>
                                    <small *ngIf="retiredVersion(cluster.zcp_version_id)">
                                        {{ "RETIRED_VERSION_NOTE" | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <!-- All -->
                        <div *ngIf="scalingAccountSelector || isEdit">
                            <div class="form-group">
                                <label for="auth_mode" [ngClass]="{ 'is-invalid': form.submitted && auth_mode.errors }">
                                    {{ "AUTHORIZATION_MODE" | translate
                                    }}<fa-icon icon="info-circle" [ngbTooltip]="AMContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #AMContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.CLUSTER_AUTHORIZATION_MODE" | translate }}
                                        </p></ng-template
                                    >
                                </label>
                                <ng-select
                                    id="auth_mode"
                                    name="auth_mode"
                                    required
                                    [items]="authOptions"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'AUTHORIZATION_MODE' | translate }}"
                                    [(ngModel)]="cluster.auth_mode"
                                    bindLabel="name"
                                    bindValue="id"
                                    #auth_mode="ngModel"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && auth_mode.errors }"
                                >
                                </ng-select>
                                <small *ngIf="cluster.auth_mode === 'zen'">
                                    {{
                                        "BROADCASTERS_WILL_BE_AUTOMATICALLY_CONFIGURED_TO_VERIFY_ALL_CONNECTIONS_AGAINST_THE_ZEN_MASTER_BACKEND_SERVER"
                                            | translate
                                    }}<br />
                                    {{ "MAKE_SURE_THAT_BROADCASTERS_WILL_BE_ABLE_TO_ACCESS_THEIR_ASSIGNED_SERVER_ON_PORT_80_AND_443" | translate }}
                                </small>
                                <small *ngIf="cluster.auth_mode === 'password'">
                                    {{ "BROADCASTERS_WILL_BE_AUTOMATICALLY_CONFIGURED_TO_AUTHORIZE_ALL_CONNECTIONS_USING_A_SINGLE_PASSWORD" | translate }}
                                </small>
                                <small *ngIf="cluster.auth_mode === 'custom'">
                                    {{ "BROADCASTERS_WILL_NOT_BE_CONFIGURED_TO_AUTHORIZE_CONNECTIONS" | translate }}<br />
                                    {{ "NOT_RECOMMENDED" | translate }}!
                                </small>
                            </div>
                            <!-- Allow access to inputs not configured in ZEN Master -->
                            <fieldset class="form-group" *ngIf="cluster.auth_mode === 'zen'">
                                <legend>
                                    {{ "ALLOW_ACCESS_TO_INPUTS_NOT_CONFIGURED_IN_ZEN_MASTER" | translate }}
                                </legend>
                                <div class="form-check form-check-inline">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="allow_unmanaged_inputs"
                                        name="allow_unmanaged_inputs"
                                        [(ngModel)]="cluster.allow_unmanaged_inputs"
                                    />
                                    <label class="form-check-label" for="allow_unmanaged_inputs">{{ "PUSH_INPUTS" | translate }}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="allow_unmanaged_outputs"
                                        name="allow_unmanaged_outputs"
                                        [(ngModel)]="cluster.allow_unmanaged_outputs"
                                    />
                                    <label class="form-check-label" for="allow_unmanaged_outputs">{{ "PULL_OUTPUTS" | translate }}</label>
                                </div>
                            </fieldset>
                            <!-- Password -->
                            <div class="form-group" *ngIf="cluster.auth_mode === 'password'">
                                <label for="auth_param" [ngClass]="{ 'is-invalid': form.submitted && auth_param.errors }"
                                    >{{ "PASSWORD" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ></label>
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="auth_param"
                                        name="auth_param"
                                        [(ngModel)]="cluster.auth_param"
                                        placeholder="{{ 'PASSWORD' | translate }}"
                                        [ngClass]="{ 'is-invalid': form.submitted && auth_param.errors }"
                                        required
                                        #auth_param="ngModel"
                                    />
                                    <button
                                        class="btn btn-outline-primary"
                                        type="button"
                                        (click)="generateStrongPassword()"
                                        title="{{ 'GENERATE_A_RANDOM_PASSWORD' | translate }}"
                                    >
                                        <fa-icon icon="key"></fa-icon>
                                    </button>
                                    <app-copy-button title="{{ 'COPY_PASSWORD' | translate }}" [stringToCopy]="cluster.auth_param"></app-copy-button>
                                    <div *ngIf="auth_param.invalid" class="invalid-feedback">
                                        <div *ngIf="auth_param.errors.required">{{ "PASSWORD" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    </div>
                                </div>
                            </div>
                            <!-- Free for All -->
                            <fieldset
                                class="form-group"
                                *ngIf="cluster.auth_mode === 'ffa'"
                                [ngClass]="{ 'is-invalid': form.submitted && !cluster.allow_unmanaged_inputs && !cluster.allow_unmanaged_outputs }"
                            >
                                <div class="form-check form-check-inline">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="ffa_inputs"
                                        name="ffa_inputs"
                                        [(ngModel)]="cluster.allow_unmanaged_inputs"
                                        [ngClass]="{ 'is-invalid': form.submitted && !cluster.allow_unmanaged_inputs && !cluster.allow_unmanaged_outputs }"
                                    />
                                    <label class="form-check-label" for="ffa_inputs">{{ "INPUTS" | translate }}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="ffa_outputs"
                                        name="ffa_outputs"
                                        [(ngModel)]="cluster.allow_unmanaged_outputs"
                                        [ngClass]="{ 'is-invalid': form.submitted && !cluster.allow_unmanaged_inputs && !cluster.allow_unmanaged_outputs }"
                                    />
                                    <label class="form-check-label" for="ffa_outputs">{{ "OUTPUTS" | translate }}</label>
                                </div>
                                <div *ngIf="form.submitted && !cluster.allow_unmanaged_inputs && !cluster.allow_unmanaged_outputs" class="invalid-feedback">
                                    {{ "SELECT_AT_LEAST_ONE_OF_INPUTS_OUTPUTS" | translate }}.
                                </div>
                                <small *ngIf="cluster.auth_mode === 'ffa'">
                                    <br />
                                    {{ "BROADCASTERS_WILL_BE_AUTOMATICALLY_CONFIGURED_TO_ALLOW_ALL_INPUTS/OUTPUTS_TO_CONNECT" | translate }}
                                </small>
                            </fieldset>

                            <!-- Termination Protection -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="termination"
                                        name="termination"
                                        [(ngModel)]="cluster.termination_protection"
                                    />
                                    <label class="form-check-label" for="termination">{{ "TERMINATION_PROTECTION" | translate }}</label>
                                </div>
                            </div>

                            <ngb-alert [dismissible]="false" [type]="'info'" *ngIf="isEdit">
                                {{ "RECONNECT_EXISTING_BROADCASTERS_TO_APPLY_NEW_AUTHORIZATION_CONFIGURATION" | translate }}
                            </ngb-alert>
                        </div>
                    </section>

                    <!-- Advanced -->
                    <section id="advanced">
                        <div class="toggle-fieldset-title" [ngClass]="{ closed: !showAdvanced }">
                            <h3
                                class="toggle-fieldset"
                                (click)="showAdvanced = !showAdvanced"
                                [ngClass]="[
                                    !showAdvanced ? 'closed' : '',
                                    form.submitted &&
                                    (form.controls.http_streaming_port?.errors ||
                                        form.controls.bx_input_bw_limit?.errors ||
                                        form.controls.bx_output_bw_limit?.errors ||
                                        form.controls.transcode_cpu_limit?.errors ||
                                        form.controls.transcode_mem_limit?.errors ||
                                        form.controls.transcode_gpu_limit?.errors ||
                                        form.controls.transcode_gpu_mem_limit?.errors ||
                                        form.controls.transcode_gpu_dec_limit?.errors ||
                                        form.controls.transcode_gpu_enc_limit?.errors)
                                        ? 'is-invalid'
                                        : ''
                                ]"
                            >
                                {{ "ADVANCED" | translate }}<fa-icon icon="plus" size="xs" [fixedWidth]="true" *ngIf="!showAdvanced"></fa-icon
                                ><fa-icon icon="minus" size="xs" [fixedWidth]="true" *ngIf="showAdvanced"></fa-icon>
                            </h3>
                        </div>
                        <fieldset class="mb-3 bordered no-bg" [ngClass]="{ 'd-none': !showAdvanced }">
                            <!-- Load Balance Pull Targets -->
                            <fieldset class="form-group">
                                <legend for="load_balance">{{ "LOAD_BALANCE_PULL_TARGETS" | translate }}</legend>
                                <mat-button-toggle-group name="load_balance" aria-label="load balance" [(ngModel)]="cluster.load_balance">
                                    <mat-button-toggle [value]="0">{{ "MINIMIZE_THE_NUMBER_OF_BROADCASTERS" | translate }}</mat-button-toggle>
                                    <mat-button-toggle [value]="1">{{ "UNIFORM_DISTRIBUTION" | translate }}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </fieldset>
                            <!-- HTTP Streaming Port -->
                            <div class="form-group">
                                <label for="http_streaming_port" [ngClass]="{ 'is-invalid': form.submitted && http_streaming_port.errors }"
                                    >{{ "HTTP_STREAMING_PORT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ></label>
                                <input
                                    type="number"
                                    id="http_streaming_port"
                                    name="http_streaming_port"
                                    placeholder="{{ 'HTTP_STREAMING_PORT' | translate }}"
                                    class="form-control form-control-sm"
                                    [ngClass]="{ 'is-invalid': form.submitted && http_streaming_port.errors }"
                                    [(ngModel)]="cluster.http_streaming_port"
                                    [min]="0"
                                    [max]="65536"
                                    required
                                    #http_streaming_port="ngModel"
                                />
                                <div *ngIf="http_streaming_port.invalid" class="invalid-feedback">
                                    <div *ngIf="http_streaming_port.errors.required">
                                        {{ "HTTP_STREAMING_PORT" | translate }} {{ "IS_REQUIRED" | translate }}.
                                    </div>
                                    <div *ngIf="http_streaming_port.errors.min || http_streaming_port.errors.max">
                                        {{ "HTTP_STREAMING_PORT" | translate }} {{ "MUST_BE_BETWEEN_1_AND_65536" | translate }}.
                                    </div>
                                </div>
                            </div>
                            <!-- Azure Public IP Prefix -->
                            <div class="form-group" *ngIf="isAzureScaling()">
                                <label for="eips">{{ "PUBLIC_IP_PREFIX" | translate }}</label>
                                <input
                                    type="text"
                                    id="eips"
                                    name="eips"
                                    placeholder="{{ 'PUBLIC_IP_PREFIX' | translate }} {{ 'RESOURCE_ID' | translate }}"
                                    class="form-control form-control-sm"
                                    [ngClass]="{ 'is-invalid': form.submitted && eips.errors }"
                                    [(ngModel)]="cluster.eips"
                                    pattern="^\/subscriptions\/.+\/resourceGroups\/.+\/providers\/Microsoft\.Network\/publicIPPrefixes\/.*$"
                                    #eips="ngModel"
                                />
                                <small>{{ "PUBLIC_IP_PREFIX_FORMAT" | translate }}</small>
                                <div *ngIf="eips.invalid" class="invalid-feedback">
                                    <div *ngIf="eips.errors.pattern">
                                        {{ "PUBLIC_IP_PREFIX" | translate }} {{ "MUST_BE_VALID_AZURE_RESOURCE_ID" | translate | lowercase }}.
                                    </div>
                                </div>
                            </div>
                            <!-- Elastic IP Allocation IDs -->
                            <div class="form-group" *ngIf="isAWSScaling()">
                                <label for="eips">{{ "ELASTIC_IP_ALLOCATION_IDs" | translate }}</label>
                                <textarea
                                    id="eips"
                                    name="eips"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="cluster.eips"
                                    #eips="ngModel"
                                    rows="3"
                                ></textarea>
                                <small>{{ "COMMA_SEPERATED_ELASTIC_IP_ALLOCATION_IDS" | translate }}</small>
                            </div>
                            <!-- Require Elastic IP -->
                            <div class="form-group" *ngIf="isAWSScaling()">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="require_eip" name="require_eip" [(ngModel)]="!!cluster.require_eip" />
                                    <label class="form-check-label" for="require_eip">
                                        {{ "REQUIRE_ELASTIC_IP" | translate }}
                                        <fa-icon icon="info-circle" [ngbTooltip]="RequiredEIPContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                        ><ng-template #RequiredEIPContent
                                            ><p class="mb-0">
                                                {{ "TOOLTIP.REQUIRE_ELASTIC_IP" | translate }}
                                            </p></ng-template
                                        >
                                    </label>
                                </div>
                            </div>
                            <!-- Bandwidth Limits -->
                            <fieldset class="form-group bordered">
                                <legend>{{ "BANDWIDTH_LIMITS" | translate }} [Mbps]</legend>
                                <div class="row">
                                    <div class="col">
                                        <label for="bx_input_bw_limit" [ngClass]="{ 'is-invalid': form.submitted && bx_input_bw_limit.errors }">{{
                                            "INPUT" | translate
                                        }}</label>
                                        <input
                                            type="number"
                                            id="bx_input_bw_limit"
                                            name="bx_input_bw_limit"
                                            placeholder="{{ 'INPUT' | translate }}"
                                            class="form-control form-control-sm"
                                            [ngClass]="{ 'is-invalid': form.submitted && bx_input_bw_limit.errors }"
                                            [(ngModel)]="cluster.bx_input_bw_limit"
                                            min="0"
                                            pattern="^\d+$"
                                            #bx_input_bw_limit="ngModel"
                                        />
                                        <div *ngIf="bx_input_bw_limit.invalid" class="invalid-feedback">
                                            <div *ngIf="bx_input_bw_limit.errors.pattern">
                                                {{ "INPUT_LIMIT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <label for="bx_output_bw_limit" [ngClass]="{ 'is-invalid': form.submitted && bx_output_bw_limit.errors }">{{
                                            "OUTPUT" | translate
                                        }}</label>
                                        <input
                                            type="number"
                                            id="bx_output_bw_limit"
                                            name="bx_output_bw_limit"
                                            placeholder="{{ 'OUTPUT' | translate }}"
                                            class="form-control form-control-sm"
                                            [ngClass]="{ 'is-invalid': form.submitted && bx_output_bw_limit.errors }"
                                            [(ngModel)]="cluster.bx_output_bw_limit"
                                            min="0"
                                            pattern="^\d+$"
                                            #bx_output_bw_limit="ngModel"
                                        />
                                        <div *ngIf="bx_output_bw_limit.invalid" class="invalid-feedback">
                                            <div *ngIf="bx_output_bw_limit.errors.pattern">
                                                {{ "OUTPUT_LIMIT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <small>{{ "BANDWIDTH_LIMITS_ARE_CHECKED_PER_BROADCASTER" | translate }}</small>
                            </fieldset>
                            <ngb-alert [dismissible]="false" [type]="'warning'" *ngIf="isAWSScaling() || isAzureScaling() || isGCPScaling()">
                                <strong>{{ "WARNING" | translate }}</strong> - {{ "PLAYBACK_MIGHT_STALL_IF_LESS_THAN_5_SEGMENTS_ARE_CONFIGURED" | translate }}
                            </ngb-alert>
                            <h4 translate>TRANSCODE_THRESHOLDS</h4>
                            <div class="row">
                                <div class="col-12 col-xs-6 mb-3">
                                    <label for="transcode_cpu_limit" [ngClass]="{ 'is-invalid': form.submitted && transcode_cpu_limit.errors }"
                                        >{{ "CPU_THRESHOLD" | translate }} [%]</label
                                    >
                                    <input
                                        type="number"
                                        id="transcode_cpu_limit"
                                        name="transcode_cpu_limit"
                                        placeholder="60"
                                        class="form-control form-control-sm"
                                        [ngClass]="{ 'is-invalid': form.submitted && transcode_cpu_limit.errors }"
                                        [(ngModel)]="cluster.transcode_cpu_limit"
                                        [min]="0"
                                        [max]="100"
                                        pattern="^\d+$"
                                        #transcode_cpu_limit="ngModel"
                                    />
                                    <div *ngIf="transcode_cpu_limit.invalid" class="invalid-feedback">
                                        <div *ngIf="transcode_cpu_limit.errors.pattern">
                                            {{ "CPU_THRESHOLD" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="transcode_cpu_limit.errors.min || transcode_cpu_limit.errors.max">
                                            {{ "CPU_THRESHOLD" | translate }} {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-xs-6 mb-3">
                                    <label for="transcode_mem_limit" [ngClass]="{ 'is-invalid': form.submitted && transcode_mem_limit.errors }"
                                        >{{ "MEMORY_THRESHOLD" | translate }} [%]</label
                                    >
                                    <input
                                        type="number"
                                        id="transcode_mem_limit"
                                        name="transcode_mem_limit"
                                        placeholder="60"
                                        class="form-control form-control-sm"
                                        [ngClass]="{ 'is-invalid': form.submitted && transcode_mem_limit.errors }"
                                        [(ngModel)]="cluster.transcode_mem_limit"
                                        [min]="0"
                                        [max]="100"
                                        pattern="^\d+$"
                                        #transcode_mem_limit="ngModel"
                                    />
                                    <div *ngIf="transcode_mem_limit.invalid" class="invalid-feedback">
                                        <div *ngIf="transcode_mem_limit.errors.pattern">
                                            {{ "MEMORY_THRESHOLD" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="transcode_mem_limit.errors.min || transcode_mem_limit.errors.max">
                                            {{ "MEMORY_THRESHOLD" | translate }} {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-xs-6 mb-3">
                                    <label for="transcode_gpu_limit" [ngClass]="{ 'is-invalid': form.submitted && transcode_gpu_limit.errors }"
                                        >{{ "GPU_THRESHOLD" | translate }} [%]</label
                                    >
                                    <input
                                        type="number"
                                        id="transcode_gpu_limit"
                                        name="transcode_gpu_limit"
                                        placeholder="60"
                                        class="form-control form-control-sm"
                                        [ngClass]="{ 'is-invalid': form.submitted && transcode_gpu_limit.errors }"
                                        [(ngModel)]="cluster.transcode_gpu_limit"
                                        [min]="0"
                                        [max]="100"
                                        pattern="^\d+$"
                                        #transcode_gpu_limit="ngModel"
                                    />
                                    <div *ngIf="transcode_gpu_limit.invalid" class="invalid-feedback">
                                        <div *ngIf="transcode_gpu_limit.errors.pattern">
                                            {{ "GPU_THRESHOLD" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="transcode_gpu_limit.errors.min || transcode_gpu_limit.errors.max">
                                            {{ "GPU_THRESHOLD" | translate }} {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-xs-6 mb-3">
                                    <label for="transcode_gpu_mem_limit" [ngClass]="{ 'is-invalid': form.submitted && transcode_gpu_mem_limit.errors }"
                                        >{{ "GPU_MEMORY_THRESHOLD" | translate }} [%]</label
                                    >
                                    <input
                                        type="number"
                                        id="transcode_gpu_mem_limit"
                                        name="transcode_gpu_mem_limit"
                                        placeholder="60"
                                        class="form-control form-control-sm"
                                        [ngClass]="{ 'is-invalid': form.submitted && transcode_gpu_mem_limit.errors }"
                                        [(ngModel)]="cluster.transcode_gpu_mem_limit"
                                        [min]="0"
                                        [max]="100"
                                        pattern="^\d+$"
                                        #transcode_gpu_mem_limit="ngModel"
                                    />
                                    <div *ngIf="transcode_gpu_mem_limit.invalid" class="invalid-feedback">
                                        <div *ngIf="transcode_gpu_mem_limit.errors.pattern">
                                            {{ "GPU_MEMORY_THRESHOLD" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="transcode_gpu_mem_limit.errors.min || transcode_gpu_mem_limit.errors.max">
                                            {{ "GPU_MEMORY_THRESHOLD" | translate }} {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-xs-6 mb-3">
                                    <label for="transcode_gpu_dec_limit" [ngClass]="{ 'is-invalid': form.submitted && transcode_gpu_dec_limit.errors }"
                                        >{{ "GPU_DECODER_THRESHOLD" | translate }} [%]</label
                                    >
                                    <input
                                        type="number"
                                        id="transcode_gpu_dec_limit"
                                        name="transcode_gpu_dec_limit"
                                        placeholder="60"
                                        class="form-control form-control-sm"
                                        [ngClass]="{ 'is-invalid': form.submitted && transcode_gpu_dec_limit.errors }"
                                        [(ngModel)]="cluster.transcode_gpu_dec_limit"
                                        [min]="0"
                                        [max]="100"
                                        pattern="^\d+$"
                                        #transcode_gpu_dec_limit="ngModel"
                                    />
                                    <div *ngIf="transcode_gpu_dec_limit.invalid" class="invalid-feedback">
                                        <div *ngIf="transcode_gpu_dec_limit.errors.pattern">
                                            {{ "GPU_DECODER_THRESHOLD" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="transcode_gpu_dec_limit.errors.min || transcode_gpu_dec_limit.errors.max">
                                            {{ "GPU_DECODER_THRESHOLD" | translate }} {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-xs-6 mb-3">
                                    <label for="transcode_gpu_enc_limit" [ngClass]="{ 'is-invalid': form.submitted && transcode_gpu_enc_limit.errors }"
                                        >{{ "GPU_ENCODER_THRESHOLD" | translate }} [%]</label
                                    >
                                    <input
                                        type="number"
                                        id="transcode_gpu_enc_limit"
                                        name="transcode_gpu_enc_limit"
                                        placeholder="60"
                                        class="form-control form-control-sm"
                                        [ngClass]="{ 'is-invalid': form.submitted && transcode_gpu_enc_limit.errors }"
                                        [(ngModel)]="cluster.transcode_gpu_enc_limit"
                                        [min]="0"
                                        [max]="100"
                                        pattern="^\d+$"
                                        #transcode_gpu_enc_limit="ngModel"
                                    />
                                    <div *ngIf="transcode_gpu_enc_limit.invalid" class="invalid-feedback">
                                        <div *ngIf="transcode_gpu_enc_limit.errors.pattern">
                                            {{ "GPU_ENCODER_THRESHOLD" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                        </div>
                                        <div *ngIf="transcode_gpu_enc_limit.errors.min || transcode_gpu_enc_limit.errors.max">
                                            {{ "GPU_ENCODER_THRESHOLD" | translate }} {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Real-time priority -->
                            <div class="form-group" *ngIf="isAWSScaling() || isAzureScaling() || isGCPScaling()">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="realtime_bx_priority"
                                        name="realtime_bx_priority"
                                        [(ngModel)]="cluster.realtime_bx_priority"
                                    />
                                    <label class="form-check-label" for="realtime_bx_priority">{{ "REAL_TIME_PRIORITY" | translate }} </label>
                                </div>
                            </div>
                            <h4 translate>BILLING_CODES</h4>
                            <!-- Inputs Billing Code -->
                            <div class="form-group">
                                <label for="inputs_billing_code" [ngClass]="{ 'is-invalid': form.submitted && inputs_billing_code.errors }">{{
                                    "INPUTS_BILLING_CODE" | translate
                                }}</label>
                                <input
                                    type="text"
                                    autocomplete="cc-csc"
                                    id="inputs_billing_code"
                                    name="inputs_billing_code"
                                    placeholder="{{ 'INPUTS_BILLING_CODE' | translate }}"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="cluster.inputs_billing_code"
                                    [ngClass]="{ 'is-invalid': form.submitted && inputs_billing_code.errors }"
                                    #inputs_billing_code="ngModel"
                                />
                            </div>
                            <!-- Inputs Billing password -->
                            <div class="form-group">
                                <label for="inputs_billing_password" [ngClass]="{ 'is-invalid': inputs_billing_password.errors }">{{
                                    "INPUTS_BILLING_PASSWORD" | translate
                                }}</label>
                                <input
                                    type="password"
                                    autocomplete="cc-csc"
                                    id="inputs_billing_password"
                                    name="inputs_billing_password"
                                    placeholder="{{ 'INPUTS_BILLING_PASSWORD' | translate }}"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="cluster.inputs_billing_password"
                                    [ngClass]="{ 'is-invalid': inputs_billing_password.errors }"
                                    #inputs_billing_password="ngModel"
                                />
                                <div *ngIf="inputs_billing_password.invalid" class="invalid-feedback">
                                    <div *ngIf="inputs_billing_password.errors.validationFailed">
                                        {{ "INPUTS_BILLING_CODE" | translate }} {{ "VALIDATION_FAILED_CHECK_CONNECTION" | translate }}.
                                    </div>
                                    <div *ngIf="inputs_billing_password.errors.invalidCode">{{ "INVALID_BILLING_CODE" | translate }}.</div>
                                </div>
                                <zx-billing-codes-check [codeControl]="inputs_billing_code" [passControl]="inputs_billing_password"> </zx-billing-codes-check>
                            </div>
                            <!-- Outputs Billing Code -->
                            <div class="form-group">
                                <label for="outputs_billing_code" [ngClass]="{ 'is-invalid': form.submitted && outputs_billing_code.errors }">{{
                                    "OUTPUTS_BILLING_CODE" | translate
                                }}</label>
                                <input
                                    type="text"
                                    autocomplete="cc-csc"
                                    id="outputs_billing_code"
                                    name="outputs_billing_code"
                                    placeholder="{{ 'OUTPUTS_BILLING_CODE' | translate }}"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="cluster.outputs_billing_code"
                                    [ngClass]="{ 'is-invalid': form.submitted && outputs_billing_code.errors }"
                                    #outputs_billing_code="ngModel"
                                />
                            </div>
                            <!-- Outputs Billing password -->
                            <div class="form-group">
                                <label for="outputs_billing_password" [ngClass]="{ 'is-invalid': outputs_billing_password.errors }">{{
                                    "OUTPUTS_BILLING_PASSWORD" | translate
                                }}</label>
                                <input
                                    type="password"
                                    autocomplete="cc-csc"
                                    id="outputs_billing_password"
                                    name="outputs_billing_password"
                                    placeholder="{{ 'OUTPUTS_BILLING_PASSWORD' | translate }}"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="cluster.outputs_billing_password"
                                    [ngClass]="{ 'is-invalid': outputs_billing_password.errors }"
                                    #outputs_billing_password="ngModel"
                                />
                                <div *ngIf="outputs_billing_password.invalid" class="invalid-feedback">
                                    <div *ngIf="outputs_billing_password.errors.validationFailed">
                                        {{ "OUTPUTS_BILLING_CODE" | translate }} {{ "VALIDATION_FAILED_CHECK_CONNECTION" | translate }}.
                                    </div>
                                    <div *ngIf="outputs_billing_password.errors.invalidCode">{{ "INVALID_BILLING_CODE" | translate }}.</div>
                                </div>
                                <zx-billing-codes-check [codeControl]="outputs_billing_code" [passControl]="outputs_billing_password"> </zx-billing-codes-check>
                            </div>
                            <!-- External Authorization -->
                            <fieldset class="form-group bordered" *ngIf="cluster.auth_mode === 'zen'">
                                <legend>
                                    <label class="form-check-label semibold">{{ "EXTERNAL_AUTH" | translate }}</label>
                                </legend>
                                <div class="row mb-3">
                                    <div class="col">
                                        <label for="external_auth_url" [ngClass]="{ 'is-invalid': external_auth_url.errors }">{{
                                            "EXTERNAL_AUTH_URL" | translate
                                        }}</label>
                                        <input
                                            type="text"
                                            id="external_auth_url"
                                            name="external_auth_url"
                                            placeholder="https://authorization.example.com/api/zen-master"
                                            class="form-control form-control-sm"
                                            [(ngModel)]="cluster.external_auth_url"
                                            [ngClass]="{ 'is-invalid': external_auth_url.errors }"
                                            #external_auth_url="ngModel"
                                        />
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col">
                                        <label for="external_auth_type" [ngClass]="{ 'is-invalid': form.submitted && external_auth_type.errors }">{{
                                            "EXTERNAL_AUTH_TYPE" | translate
                                        }}</label>
                                        <input
                                            type="text"
                                            id="external_auth_type"
                                            name="external_auth_type"
                                            placeholder="{{ 'EXTERNAL_AUTH_TYPE_PH' | translate }}"
                                            class="form-control form-control-sm"
                                            [ngClass]="{ 'is-invalid': form.submitted && external_auth_type.errors }"
                                            [(ngModel)]="cluster.external_auth_type"
                                            #external_auth_type="ngModel"
                                        />
                                    </div>
                                    <div class="col">
                                        <label
                                            for="external_auth_credentials"
                                            [ngClass]="{ 'is-invalid': form.submitted && external_auth_credentials.errors }"
                                            >{{ "EXTERNAL_AUTH_CREDS" | translate }}</label
                                        >
                                        <input
                                            type="text"
                                            id="external_auth_credentials"
                                            name="external_auth_credentials"
                                            placeholder="{{ 'EXTERNAL_AUTH_CREDS_PH' | translate }}"
                                            class="form-control form-control-sm"
                                            [ngClass]="{ 'is-invalid': form.submitted && external_auth_credentials.errors }"
                                            [(ngModel)]="cluster.external_auth_credentials"
                                            #external_auth_credentials="ngModel"
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <!-- User Data -->
                            <div class="form-group" *ngIf="isAWSScaling()">
                                <label for="user_data_input"> {{ "LAUNCH_SCRIPT" | translate }} </label>
                                <textarea
                                    id="user_data_input"
                                    name="user_data_input"
                                    class="form-control"
                                    [(ngModel)]="userData"
                                    #user_data_input
                                    placeholder="{{ 'LAUNCH_SCRIPT' | translate }}"
                                    rows="3"
                                ></textarea>
                                <small
                                    >{{ "SEE_AWS_DOCUMENTATION_FOR_VALID_LAUNCH_SCRIPTS" | translate }}
                                    <a target="_blank" href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/user-data.html#user-data-shell-scripts">here</a
                                    >.</small
                                >
                            </div>
                            <!-- Auto install AgentZ -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="auto_install_agentz"
                                        name="auto_install_agentz"
                                        [(ngModel)]="cluster.auto_agentz"
                                    />
                                    <label class="form-check-label" for="auto_install_agentz">{{ "AUTO_INSTALL_AGENTZ" | translate }} </label>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="isAWSScaling()">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="detailed_monitoring"
                                        name="detailed_monitoring"
                                        [(ngModel)]="cluster.detailed_monitoring"
                                        [disabled]="scalingAccountSelector === 'manual-0' || scalingAccount?.type === 'manual'"
                                    />
                                    <label class="form-check-label" for="detailed_monitoring">{{ "ENHANCED_MONITORING" | translate }} </label>
                                </div>
                            </div>
                        </fieldset>
                    </section>
                    <ngb-alert [dismissible]="false" [type]="'danger'" class="mb-0" class="mb-3" *ngIf="customError">
                        <strong>{{ "ERROR" | translate }}</strong
                        >:&nbsp;{{ customError }}
                    </ngb-alert>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving || customError"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
