<div class="details-form d-flex flex-wrap flex-row grid-gap-3">
    <div class="flex-1 flex-basis-min">
        <div class="form-group">
            <h5 translate>API_CREDENTIALS</h5>
            <div>
                <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">{{ "SHOW" | translate }}</a>
                <span *ngIf="!pwVisible">&nbsp;/&nbsp;</span>
                <a href="javascript:void(0)" (click)="copyPassword(cluster.api_password)" *ngIf="!pwVisible">{{ "COPY_PASSWORD" | translate }}</a>
                <span *ngIf="pwVisible"
                    >{{ cluster.api_user }}
                    <a href="javascript:void(0)" title="{{ 'COPY_USER' | translate }}" (click)="copyPassword(cluster.api_user)"
                        ><fa-icon icon="clipboard" size="sm"></fa-icon
                    ></a>
                    / {{ cluster.api_password }}
                    <a href="javascript:void(0)" title="{{ 'COPY_PASSWORD' | translate }}" (click)="copyPassword(cluster.api_password)"
                        ><fa-icon icon="clipboard" size="sm"></fa-icon></a
                    ><app-password-strength-icon class="d-inline-block ms-1" [password]="cluster.api_password"></app-password-strength-icon></span
                ><a class="ms-1" href="javascript:void(0)" (click)="pwVisible = false" *ngIf="pwVisible">{{ "HIDE" | translate }}</a>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>{{ "ALTERNATIVE_CLUSTER" | translate }}</h5>
            <div>
                <zx-cluster
                    *ngIf="alternativeCluster"
                    [model]="alternativeCluster"
                    [showOtherIcons]="true"
                    [showStatusIcon]="true"
                    [showStatusText]="false"
                    [showLink]="true"
                    [showTag]="false"
                ></zx-cluster>
                <span *ngIf="!alternativeCluster">-</span>
            </div>
        </div>
        <div class="form-group" *ngIf="numberOfTotalIps > 0">
            <h5 translate title="{{ 'ELASTIC_IPS' | translate }}">ELASTIC_IPS</h5>
            <div class="outlet-container">
                <span>{{ numberOfRemainingIps }} / {{ numberOfTotalIps }} Available</span>
                <div class="ms-1" *ngIf="!(numberOfRemainingIps > 0)">
                    <fa-icon icon="exclamation-circle" class="status-warning" [ngbTooltip]="infoTooltipRef" triggers="hover click" [closeDelay]="500"></fa-icon>
                    <ng-template #infoTooltipRef>{{ infoTooltip || "TOOLTIP.NO_MORE_ELASTIC_IP" | translate }}</ng-template>
                </div>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>SCALING_ACCOUNT</h5>
            <div>
                <span>{{ cluster._frontData.scaling | translate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="scalingAccount && scalingAccount.name">
            <h5 translate>ACCOUNT_NAME</h5>
            <div>
                <span>{{ scalingAccount.name }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="cluster && cluster.rtmp_input_port > 0">
            <h5 translate>RTMP_SERVER</h5>
            <div>
                <span>{{ "PORT" | translate }}&nbsp;</span>
                <span>{{ cluster.rtmp_input_port }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>AUTHORIZATION_MODE</h5>
            <div>
                <span *ngIf="cluster.auth_mode === 'zen'">{{ "ZEN_MASTER" | translate }}</span>
                <span *ngIf="cluster.auth_mode === 'password'">{{ "GLOBAL_PASSWORD" | translate }}</span>
                <span *ngIf="cluster.auth_mode === 'ffa'">{{ "FREE_FOR_ALL" | translate }}</span>
                <span *ngIf="cluster.auth_mode === 'custom' || !cluster.auth_mode">{{ "MANUAL" | translate }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="cluster && cluster.auth_mode === 'password'">
            <h5 translate>AUTHORIZATION_PASSWORD</h5>
            <div>
                <a href="javascript:void(0)" (click)="authVisible = true" *ngIf="!authVisible">
                    {{ "SHOW_PASSWORD" | translate }}
                </a>
                <span *ngIf="authVisible">{{ cluster.auth_param }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>LOG_SCTE_35</h5>
            <div>
                <span *ngIf="cluster.configure_scte_reports">{{ "ENABLED" | translate }}</span>
                <span *ngIf="!cluster.configure_scte_reports">{{ "DISABLED" | translate }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>TERMINATION_PROTECTION</h5>
            <div>
                <span>{{ cluster.termination_protection ? ("ENABLED" | translate) : ("DISABLED" | translate) }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>ENHANCED_MONITORING</h5>
            <div>
                <span>{{ cluster.detailed_monitoring ? ("ENABLED" | translate) : ("DISABLED" | translate) }}</span>
            </div>
        </div>
    </div>

    <div class="flex-1 flex-basis-min" *ngIf="cluster && cluster._frontData.is_auto_scaling">
        <div class="form-group">
            <h5 translate>REGION</h5>
            <div>
                <span>{{ cluster._frontData.prettyRegionName || cluster.region }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="cluster._frontData.is_gcp">
            <h5 translate>ZONES</h5>
            <div>
                <span>{{ cluster._frontData.prettyGCPZones }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="!cluster._frontData.is_azure && !cluster._frontData.is_gcp">
            <h5 translate>KEY_PAIR</h5>
            <div>
                <span>{{ cluster.key_pair }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="cluster._frontData.is_azure && cluster.accessKey">
            <h5 translate>SSH_KEY</h5>
            <div>
                <span *ngIf="cluster.accessKey.readOnly || (!cluster.accessKey.readOnly && !cluster.accessKey.hasRsaPrivate)">{{
                    cluster.accessKey.name
                }}</span>
                <a *ngIf="!cluster.accessKey.readOnly && cluster.accessKey?.hasRsaPrivate" href="/api/access_keys/{{ cluster.accessKey.id }}/download">
                    <fa-icon icon="download" size="sm"></fa-icon>
                    {{ cluster.accessKey.name }}
                </a>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>ACTIVATION_KEY</h5>
            <div>
                <span>{{ cluster.activation_key }}</span
                ><span class="ms-1 text-primary cursor-pointer"
                    ><fa-icon icon="clipboard" size="sm" (click)="copyPassword(cluster.activation_key)"></fa-icon
                ></span>
            </div>
        </div>
        <div class="form-group" *ngIf="!cluster._frontData.is_gcp">
            <h5 translate>VPC</h5>
            <div>
                <span>{{ cluster._frontData.vpcName }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>SUBNET</h5>
            <div>
                <span>{{ cluster._frontData.prettyGCPSubnet || cluster._frontData.subnetName }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="!cluster._frontData.is_gcp">
            <h5 translate>SECURITY_GROUP</h5>
            <div>
                <span>{{ cluster._frontData.securityGroupName }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>INSTANCE_TYPE</h5>
            <div>
                <span>{{ cluster.instance_type }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="cluster.gcp_account_id != null">
            <h5 translate>GPU_TYPE</h5>
            <div>
                <span>{{ cluster.nvidia_type || ("NONE" | translate) }}</span>
            </div>
        </div>
        <div class="form-group">
            <h5 translate>BROADCASTER_VERSION</h5>
            <div>
                <span>{{ cluster.broadcaster_version }}</span>
            </div>
        </div>
    </div>
</div>
